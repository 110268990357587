.background {
    background-color: white;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    padding: 50px;
    padding-top: 100px;
}

.heading {
    padding: 150px;
}