* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.main,
.services,
.about,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.main {
  background-image: url('./images/accounting.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('./images/sky.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about {
  background-image: url('./images/logo-sign.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px;
}

.contact {
  background-image: url('./images/logo.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.title {
  text-align: center;
  margin: 20px;
  align-items: center;
  justify-content: center;
}

.images {
  margin: auto;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-heading {
  padding: 50px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 35px;
}

.description {
  padding: 25px;
  padding-left: 15vw;
  padding-right: 15vw;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 25px;
}