.background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.img {
    display: flex;
    width: 100vw;
    height: auto;
    justify-content: center;
    align-items: center;
}

.mission-heading {
    margin: 100px;
    margin-left: 50px;
    color: lightgreen;
}

.mission {
    margin: 50px;
    margin-left: 100px;
}