.background {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100vw;
    height: 200vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    padding: 50px;
    padding-top: 100px;
}

.img {
    display: flex;
    margin: 0px;
    width: 75vw;
    height: auto;
    justify-content: center;
    align-items: center;
}