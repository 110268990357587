.background {
    background-color: white;
    width: 100vw;
    height: 300vh;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    padding: 50px;
    padding-top: 100px;
}

.img {
    margin: 0px;
    width: 65vw;
    height: auto;
}

.heading {
    padding: 15vw;
    padding-top: 250px;

}

.space {
    background-color: white;
    width: 100vw;
    height: 50vh;
}